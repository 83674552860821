var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"rule"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"totalWorkerAccident","rules":"required","custom-messages":{ required: 'Tổng số người bị nạn là bắt buộc' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Tổng số người bị nạn")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number"},model:{value:(_vm.dataGeneral.totalWorkerAccident),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "totalWorkerAccident", $$v)},expression:"dataGeneral.totalWorkerAccident"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"totalWorkerAccident","rules":"required","custom-messages":{ required: 'Số người lao động bị chết là bắt buộc' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Số lao động bị chết")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number"},model:{value:(_vm.dataGeneral.totalWorkerDeath),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "totalWorkerDeath", $$v)},expression:"dataGeneral.totalWorkerDeath"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"totalWorkerInjury","rules":"required","custom-messages":{ required: 'Số lao động bị thương nặng là bắt buộc' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Số lao động bị thương nặng")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number"},model:{value:(_vm.dataGeneral.totalWorkerInjury),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "totalWorkerInjury", $$v)},expression:"dataGeneral.totalWorkerInjury"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"totalWorker","rules":"required","custom-messages":{ required: 'Tổng số người lao động là bắt buộc' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Tổng số người lao động")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number"},model:{value:(_vm.dataGeneral.totalWorker),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "totalWorker", $$v)},expression:"dataGeneral.totalWorker"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"totalFemaleWorker","rules":"required","custom-messages":{ required: 'Tổng số lao động nữ là bắt buộc' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Tổng số lao động nữ")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number"},model:{value:(_vm.dataGeneral.totalFemaleWorker),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "totalFemaleWorker", $$v)},expression:"dataGeneral.totalFemaleWorker"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"totalSalary","rules":"required","custom-messages":{ required: 'Tổng quỹ lương là bắt buộc' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Tổng quỹ lương")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number"},model:{value:(_vm.dataGeneral.totalSalary),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "totalSalary", $$v)},expression:"dataGeneral.totalSalary"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }