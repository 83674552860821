<template>
  <div>

    <validation-observer ref="rule">
      <b-form>
        <b-row>
          <b-col
            xl="4"
            lg="4"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              name="totalWorkerAccident"
              rules="required"
              :custom-messages="{ required: 'Tổng số người bị nạn là bắt buộc' }"
            >
              <b-form-group>
                <label>Tổng số người bị nạn</label>
                <b-form-input
                  v-model="dataGeneral.totalWorkerAccident"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              name="totalWorkerAccident"
              rules="required"
              :custom-messages="{ required: 'Số người lao động bị chết là bắt buộc' }"
            >
              <b-form-group>
                <label>Số lao động bị chết</label>
                <b-form-input
                  v-model="dataGeneral.totalWorkerDeath"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              name="totalWorkerInjury"
              rules="required"
              :custom-messages="{ required: 'Số lao động bị thương nặng là bắt buộc' }"
            >
              <b-form-group>
                <label>Số lao động bị thương nặng</label>
                <b-form-input
                  v-model="dataGeneral.totalWorkerInjury"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              name="totalWorker"
              rules="required"
              :custom-messages="{ required: 'Tổng số người lao động là bắt buộc' }"
            >
              <b-form-group>
                <label>Tổng số người lao động</label>
                <b-form-input
                  v-model="dataGeneral.totalWorker"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            xl="4"
            lg="4"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              name="totalFemaleWorker"
              rules="required"
              :custom-messages="{ required: 'Tổng số lao động nữ là bắt buộc' }"
            >
              <b-form-group>
                <label>Tổng số lao động nữ</label>
                <b-form-input
                  v-model="dataGeneral.totalFemaleWorker"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            xl="4"
            lg="4"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              name="totalSalary"
              rules="required"
              :custom-messages="{ required: 'Tổng quỹ lương là bắt buộc' }"
            >
              <b-form-group>
                <label>Tổng quỹ lương</label>
                <b-form-input
                  v-model="dataGeneral.totalSalary"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    dataGeneral: {
      type: Object,
      default: _ => {},
    },
  },

  data() {
    return {
      disableProp: false,
    }
  },
}
</script>

<style>

</style>
